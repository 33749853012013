import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import { setupPageGuard } from './permission'
import { ChatLayout } from '@/views/chat/layout'
import { isWechatEnvironment, wxAuth } from '@/utils/is'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Root',
    component: ChatLayout,
    redirect: '/uuid',
    children: [
      {
        path: '/uuid/:uuid?',
        name: 'Chat',
        meta: {
          title: ' - 首页',
        },
        component: () => import('@/views/chat/index.vue'),
      },
    ],
  },
  {
    path: '/ai',
    name: 'ChatAi',
    meta: {
      title: ' - 创作',
    },
    component: () => import('@/views/ai/index.vue'),
  },
  {
    path: '/app',
    name: 'ChatApp',
    meta: {
      title: ' - ai应用',
    },
    component: () => import('@/views/app/index.vue'),
  },
  {
    path: '/draw',
    name: 'Draw',
    meta: {
      title: ' - 绘画',
    },
    component: () => import('@/views/draw/index.vue'),
  },
  {
    path: '/draw/loading',
    name: 'DrawLoading',
    meta: {
      title: ' - 绘制中',
    },
    component: () => import('@/views/draw/loading.vue'),
  },
  {
    path: '/draw/detail',
    name: 'DrawDetail',
    meta: {
      title: ' - 查看作品',
    },
    component: () => import('@/views/draw/detail/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: ' - 登录',
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/use',
    name: 'Use',
    meta: {
      title: ' - 使用协议',
    },
    component: () => import('@/views/use/index.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      title: ' - 隐私政策',
    },
    component: () => import('@/views/privacy/index.vue'),
  },
  {
    path: '/loading',
    name: 'loading',
    meta: {
      title: ' - 登录',
    },
    component: () => import('@/views/loading/index.vue'),
  },
  {
    path: '/user',
    name: 'ChatUser',
    meta: {
      title: ' - 用户',
    },
    component: () => import('@/views/user/index.vue'),
  },
  {
    path: '/team',
    name: 'ChatTeam',
    meta: {
      title: ' - 团队中心',
    },
    component: () => import('@/views/team/index.vue'),
  },
  {
    path: '/team/fans',
    name: 'ChatTeamFans',
    meta: {
      title: ' - 团队粉丝',
    },
    component: () => import('@/views/team/fans/index.vue'),
  },
  {
    path: '/team/share',
    name: 'ChatTeamShare',
    meta: {
      title: ' - 分享海报',
    },
    component: () => import('@/views/team/share/index.vue'),
  },
  {
    path: '/team/order',
    name: 'ChatTeamOrder',
    meta: {
      title: ' - 分销订单',
    },
    component: () => import('@/views/team/order/index.vue'),
  },
  {
    path: '/team/withdraw',
    name: 'ChatTeamWithdraw',
    meta: {
      title: ' - 提现申请',
    },
    component: () => import('@/views/team/withdraw/index.vue'),
  },
  {
    path: '/team/withdraw/list',
    name: 'ChatTeamWithdraw/list',
    meta: {
      title: ' - 提现记录',
    },
    component: () => import('@/views/team/withdraw/list/index.vue'),
  },
  {
    path: '/team/withdraw/detail',
    name: 'ChatTeamWithdraw/detail',
    meta: {
      title: ' - 提现明细',
    },
    component: () => import('@/views/team/withdraw/index.vue'),
  },
  {
    path: '/user/pay',
    name: 'ChatUserPay',
    meta: {
      title: ' - 开通会员',
    },
    component: () => import('@/views/user/pay/index.vue'),
  },
  {
    path: '/user/card',
    name: 'ChatUserCard',
    meta: {
      title: ' - 卡密支付',
    },
    component: () => import('@/views/user/card/index.vue'),
  },
  {
    path: '/user/wx',
    name: 'ChatUserWX',
    meta: {
      title: ' - 联系客服',
    },
    component: () => import('@/views/user/wx/index.vue'),
  },
  {
    path: '/user/edit',
    name: 'ChatUserEdit',
    meta: {
      title: ' - 修改密码',
    },
    component: () => import('@/views/user/edit/index.vue'),
  },
  {
    path: '/user/order',
    name: 'ChatUserOrder',
    meta: {
      title: ' - 订单记录',
    },
    component: () => import('@/views/user/order/index.vue'),
  },
  {
    path: '/user/task',
    name: 'Task',
    meta: {
      title: ' - 任务',
    },
    component: () => import('@/views/user/task/index.vue'),
  },
  {
    path: '/pdf',
    name: 'Pdf',
    meta: {
      title: ' - pdf',
    },
    component: () => import('@/views/pdf/index.vue'),
  },
  {
    path: '/pdf/list',
    name: 'PdfList',
    meta: {
      title: ' - pdf',
    },
    component: () => import('@/views/pdf/list/index.vue'),
  },
  {
    path: '/pdf/chat',
    name: 'PdfChat',
    meta: {
      title: ' - pdf',
    },
    component: () => import('@/views/pdf/chat/index.vue'),
  },
  {
    path: '/emailValidation',
    name: 'emailValidation',
    component: () => import('@/views/exception/emailValidation/index.vue'),
  },

  {
    path: '/404',
    name: '404',
    component: () => import('@/views/exception/404/index.vue'),
  },

  {
    path: '/500',
    name: '500',
    component: () => import('@/views/exception/500/index.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    redirect: '/404',
  },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

setupPageGuard(router)

const whiteList = [
  '/login',
  '/loading',
  '/404',
  '/403',
  '/500',
  '/use',
  '/privacy',
  '/draw/loading',
  '/draw/detail',
  '/privacy',
  '/use',
  // '/pdf',
  '/pdf/list',
  '/pdf/chat',
]

const toRedirect = (to, next) => {
  if (!localStorage.getItem('SECRET_TOKEN')) {
    if (!['/login', '/loading', '/privacy', '/use'].includes(to.path))
      next('/login')
		  else
      next()
  }
  else {
    if (to.path.includes('/uuid')) {
      return next()
    }
    else if (whiteList.includes(to.path)) {
      return next()
    }
    else {
      try {
        if (
          JSON.parse(localStorage.getItem('menuList') || '[]').includes(
            to.path,
          )
        )
          return next()
				 else
          return next(false)
      }
      catch (err) {
        return next()
      }
    }
  }
}

export async function setupRouter(app: App) {
  app.use(router)
  const url = window.location.href
  const codeRegex = /shareKey=([^&]+)/
  const match = url.match(codeRegex)
  if (match && match[1])
    localStorage.setItem('inviteKey', match[1]?.split('#')?.[0])

  router.beforeEach((to, from, next) => {
    if (isWechatEnvironment()) {
      if (!localStorage.getItem('SECRET_TOKEN')) {
        if (!['/login', '/loading', '/privacy', '/use'].includes(to.path)) {
          wxAuth({})
          next()
        }
        else {
          next()
        }
      }
      else {
        toRedirect(to, next)
      }
    } else {
      toRedirect(to, next)
    }
  })

  await router.isReady()
}
